import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`This tutorial provides instructions for installing Visual Studio Code on Ubuntu. While the steps outlined here may work for other versions of Ubuntu (and some non-Ubuntu Linux distributions), these instructions were tested against `}<strong parentName="p">{`Ubuntu 20.04 LTS`}</strong>{`.`}</p>
    <h2 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h2>
    <ul>
      <li parentName="ul">{`A working `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`code`}</code>{` installation`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`Before starting this tutorial, ensure that ...`}</p>
    <ul>
      <li parentName="ul">{`You have a working `}<a parentName="li" {...{
          "href": "/tutorials/virtualbox-install-ubuntu"
        }}>{`Ubuntu 20.04 LTS installation`}</a></li>
      <li parentName="ul">{`You are `}<a parentName="li" {...{
          "href": "/tutorials/linux-command-line-basics"
        }}>{`comfortable with the basics of the Linux command line`}</a>{` `}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <p><a parentName="p" {...{
        "href": "https://code.visualstudio.com/docs",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Visual Studio Code (VS Code)`}</a>{` is a free and `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/docs/editor/extension-gallery",
        "target": "_self",
        "rel": "nofollow"
      }}>{`extensible`}</a>{` cross-platform code editor released by Microsoft.  `}</p>
    <h1 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installation`}</h1>
    <h2 {...{
      "id": "step-1-installing-visual-studio-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-1-installing-visual-studio-code",
        "aria-label": "step 1 installing visual studio code permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1: Installing Visual Studio Code`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In your VM, open your web browser (ex. Firefox), and paste the following link:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://code.visualstudio.com/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`https://code.visualstudio.com/`}</a></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.deb`}</code>{` icon to download the VS Code. `}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c50840e446f944a268f6b331a8bfe3f7/44a54/vscode-download.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.91891891891892%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPElEQVQ4y22SzU9cZRSH518wblwULNNy53689/vOB/cOzAwdLnbAplMobQkIlGHKiCUkjWx0oak1JmhMXBjjwr01BWpom5guunSh3TbVFqTRpBAYtKWxRpPH3Esl0br45STvOe/z/s57TuLaUZcrC5fYfbDOox/vsnP3Do3JCRxdUAl8erJZwmyGQhDQmc8T5HIEHR2xdE1DlWU0RUFVNQJDJTHXN8H3337Hkz+esrm5wdO/4Ifbt7m68B43P/mIGwuX+ObD97nxxeesXL/OtZWVfS0tLnJ1eZmvl5f46vKXXFlcIvHa8BvcunmLjc0N1tbW+Gn9Aev3V9lavcfDtfs8XI10j61ffqa5s8P2dpNms8n29jZbW1s83n3C49//ZLP5G78+2iVxce5NSkE3+XwJv6dKb6VKpe84vZXjlLpfoVgK6S4fpVAKKRTKdHWVKRXDOBaCAh3hSZLnL2N1vcpAdZDERKWHVJtKMimTPKQhtQsk2aZdTaMoNkK1EJqNplqoiomqWAjhxJJTOm1JlYNqjgMt7XTmu0kMn64jZAeh7xVFl2OAZmMYHplMnmw2Ty7Xhe8XyeU6MQx3HxrJEBYpSRCGfSSGhxoI2UaLYU4cFc1BiRyoNuXeYwwOjTA4OEy1eoqwtz+GRA/+A9R1F0kS9PRUSFw8ksNWbdRnRYZw8HWHwHBJawYFyyT0fbo6fPI5nyCdwRTmMwN7nfwL6B2bRyhRC1HSQRcOad3FVwVhtow28jFO9S0ODHzAS2Of8cKZT2kt19EVgSbc5x1mghNosr3/fxE0cquoBp53BDH4Lnb/BZKVC7QMvMOLfW/T6g+hq8aey/8C80YGTbaeG0hcLGtYyTYs1cCIHCkG2mEJTZLjvPZ/Lc+fn8cUHrrhYuhuPEHT9DAND9NMY7o+draAnSniZIvYro9pZeKcFUcv3oaUbBCG/STUFoW2VolDh/d2MZUSZNLRuqTxPBfXcXAsE8+1cR0rjpZpYFsmrmUgSwqSpHHw5XaKhTKJ6fE6Z8enqE01mJycpl5vMDMzw+zsLI3G60xPR+c1xsbGqNVq1OrnODc1xcj4JP2nRjkzepYTp2v0nRylMTPH32MNIP1SpwBYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-download",
            "title": "vscode-download",
            "src": "/static/c50840e446f944a268f6b331a8bfe3f7/fcda8/vscode-download.png",
            "srcSet": ["/static/c50840e446f944a268f6b331a8bfe3f7/12f09/vscode-download.png 148w", "/static/c50840e446f944a268f6b331a8bfe3f7/e4a3f/vscode-download.png 295w", "/static/c50840e446f944a268f6b331a8bfe3f7/fcda8/vscode-download.png 590w", "/static/c50840e446f944a268f6b331a8bfe3f7/efc66/vscode-download.png 885w", "/static/c50840e446f944a268f6b331a8bfe3f7/44a54/vscode-download.png 1017w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`In the pop-up window, select the `}<em parentName="li">{`Save File`}</em>{` option, and hit `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`OK`}</code>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "494px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df15146d4abde01d9cae60a7b4766878/d72d4/vscode-save-file.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.27027027027026%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPklEQVQ4y4VT227aQBD1v0QpSR9TKZX6EDWpKLnQ9Ff6W0mIxKVSnPQP+tA8GAx2cMDg+42AsTHwcKqZxJRGRbV0PDu767Pn7IyF3TcFvN3ZQWF7G+/393FeLuO4VMLpcQnFT0f4eHCAo8NDnJ2c8NqXchmfi0V8PT/H2ekp9t/tYWtrC3u7BXz7UIBwfX2Ni6tLXF1colKpoF6roV6vo1qtQry5wa0oMn7c3TFEUVzNU/zeaKBWraFRq+G2UYewSFIEugnbdeB7HkzThOf76PX6eHp6wmKxQJomiOMYaZrif48w9SJ4rS46Dwr6vT663S66mgZZltFsSmi1KDbx6/4emqYx8XQ63Qghm8/hRyFUVeUPFEVBp9PBw4OKTrvN+aOmQVUUOK7LKmezGcfXoHmBLSUpwiBAFEVwXRdBEMDzPAbNUU7rlmWzipyQYo4Voee50HUdnuvCsizYts3wfZ+j4xAcxnK5RJZtVkdR8H0Pj71HtkZ3RdZVRYXcaqEly2i3ZUhSE5IkwXYcJEmCLMtWymhMRJPJ5FnhfD6H1TO5qoZhIAhDtmnbFqtcV2qYJsbjMZPmRcjHo9HoWWESJ1B+trm6g8EA0Wi02kwVfV1VUkKkeaTWyiMTzrIMk3jC/Ud3qA8GTKz3dQyHQxiGyflgOOTcNA3eR26iaPSXfbZMr/WTSfqfkyeskPJcVa6YxnnF10mFvEI0+S+sr+WHh2G4+nMIdOfTJHmxvKFJN4GUUpsRSfJCQi0Xx1Mm/A0j3e04/gUzVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-save-file",
            "title": "vscode-save-file",
            "src": "/static/df15146d4abde01d9cae60a7b4766878/d72d4/vscode-save-file.png",
            "srcSet": ["/static/df15146d4abde01d9cae60a7b4766878/12f09/vscode-save-file.png 148w", "/static/df15146d4abde01d9cae60a7b4766878/e4a3f/vscode-save-file.png 295w", "/static/df15146d4abde01d9cae60a7b4766878/d72d4/vscode-save-file.png 494w"],
            "sizes": "(max-width: 494px) 100vw, 494px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Navigate to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Files`}</code>{` `}{`→`}{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Downloads`}</code>{`  and double-click on the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`.deb`}</code>{` file that you just downloaded. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ec7319a57f138c5c9c46f2367de4c638/af590/vscode-file-click.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.70270270270271%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwUlEQVQ4y53TSWgTYRTA8Rw9uIJ6EMGDW63NVNGafSm1kLbowYO4ICgeFGkSsCqCJVWLCwrFImg1LVoril4FUQQRwaOgCB6atLGR1rQ6JpnMmjTzl0yldUOqA49vho/58d773mc71FiLb0sd/vogfp8fv9+P1+vF4/FY69S7G4/bjdfjsfYDgcBPEQqFaGlpobm5CdvDXc24Vws4hDoEwc6G2lrcLhcBvx+Xw4Hb6aQ+GMQXDOLy+bALAlVr17Kuqmo67DU1CHY7gr0GW8PKLbjmrmFPaCdHj7cRDUc4HYtxrrOTWPspTnd0cOnsGS6EW4nt3cfRg/uJhMNEIxGilTUcJtLaOh22/e0DrLMto+9yDyVzkvRIGqlQwDAMdE2jWJoEWaT88i2lk3fh0yh/e2wHXVtxzF9Fz8WryGqBVOoDoiii6zqKoqAZRciLqKPjpJ+9xpzIYBo6JmCWy5im+VPYnt/rxbFgCTe6riIrMkPJISSpgKZpqIqCXirBxBiZoUHevH+HmkpCqTgF/oJZYPzYCRoWr6ev6yaaofExnUaSpBmwWMSUciipBNJwguJYuiJNg7+VHN4RIrDcya3uOHlVIZEcYvRTxoJUTUc3DH79zfr+A2aBkd3b2TSvhv6uHnLiBOnEICOJQXLiFzS5gK6qU+VU+vW9Z3/NcFvjPuqWbuJWcDPFSJAv0Sa+hhsRD/sYP7CRXDw2lZVZ/iPwe4bn+3Au3ci9tjbkR72MPehBfjxA4el98k/uorx99R00Zwc2C17qFlRz+9oAeWA48xlpEnRAA4wfSpsV+OLEXoQ5q7jeeYVcTiSVTPJ5PIOuKqiVHmrq9KHMCgytd1G/wkl/dxxVV0mPjMyMjapaA/5PGTqid3AurOL2lTjZfNYa7IIs/z/obDqCY1E1/d29lDHJZrPWlatg1tXTtJn5mwX4DQV1F2RprgHqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-file-click",
            "title": "vscode-file-click",
            "src": "/static/ec7319a57f138c5c9c46f2367de4c638/fcda8/vscode-file-click.png",
            "srcSet": ["/static/ec7319a57f138c5c9c46f2367de4c638/12f09/vscode-file-click.png 148w", "/static/ec7319a57f138c5c9c46f2367de4c638/e4a3f/vscode-file-click.png 295w", "/static/ec7319a57f138c5c9c46f2367de4c638/fcda8/vscode-file-click.png 590w", "/static/ec7319a57f138c5c9c46f2367de4c638/af590/vscode-file-click.png 626w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`The installation window should appear. Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Install`}</code>{`. `}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/20717971e9147d0847740d34b28d8bf1/709cb/vscode-installation-page.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.810810810810814%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVQoz42RTUvDQBCG83/zYZXq1avg1f/g2ZMHETwpKIhYUKhSKkla0myy2W02my98ZbdN2hRpc3jCO7szb2ZnjLPhELZlaSzLxJHjtDi2Dds0cTwY4FTlmaY+cyyri23Btm2cnzgwqqpCXddA8QuUQLQgSNMUQgiU5epO5ZRlqfU+iqqCITIBmUm8+u+4mzzgez4BTzhiGmtTKWUvsizTGOpTyAI341tcvlxhNP9AIQowzsE5B2OsZTfepmOo/pBGS7A5RTAL4Hmexvd9uGutcF1XnzXaW+swDNsujUbkRY6IRuDLJfI8b5+idMNuvHu37nBVSEiIOI61VnPNpHrCZjZ9ULXtk4MgQBRHkFmONJFIKO8spcnbZ9aZIU0oClli7L/h+ukCo59HHaci7RQc2nZrSAgBSzi8YIrnz3tMZ18gJEIYLvR2t7vrZahWT2kMRhlSJlGVdbuEvt1pw+3kzXZVJ+LfGR0y/APaJ3qMBJYBUgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-installation-page",
            "title": "vscode-installation-page",
            "src": "/static/20717971e9147d0847740d34b28d8bf1/fcda8/vscode-installation-page.png",
            "srcSet": ["/static/20717971e9147d0847740d34b28d8bf1/12f09/vscode-installation-page.png 148w", "/static/20717971e9147d0847740d34b28d8bf1/e4a3f/vscode-installation-page.png 295w", "/static/20717971e9147d0847740d34b28d8bf1/fcda8/vscode-installation-page.png 590w", "/static/20717971e9147d0847740d34b28d8bf1/efc66/vscode-installation-page.png 885w", "/static/20717971e9147d0847740d34b28d8bf1/709cb/vscode-installation-page.png 1034w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You may be asked to enter your user password. Follow the directions presented.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "403px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3267983844ab8112af9d3b41bcd0fadd/045fd/vscode-user-password.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.24324324324323%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAACZ0lEQVQ4y5VU21LjMAzNJ/MP/Ao/wPuyVx72BbZD2m5h2rS0NKFNUpq7Hds9O1IQhEsf1jMnki3lWJZseW3bQmsNYwycczgcDp10jqVzlqW19sVGunu2y/8kCZ6QZXmOzWaD9XqNON4iiiLEcYwkSVhP0xRhGGK73SJOEpY0L8uS/yceAhPSTmT8Mxjg5saHP/Th+z7G4zF8f4jBYIDZbIbh0MfV1RXPR6MRrq+vmZAilig9+tR1jTzPUZYF61VVsVRKMci5mzdMQL40b5oaWZbxuhzdo3D3+z2CYIa7u1ssFgsEQYDlcomHhzVH5Ps3mEwmWK2WHOn9/QLzeYD5fI7pdIq6rl4JJYeUcEm6FKdpGk4FkVN++4Xp+/UL48mEIMfrQ4bk6RheIpRy9yFOtPtqtcL5+fmnBJ/BO2agaGlQpU9PTz9E+F+E/eOenZ3h5OSErwgN2ugY2Yccvo+gLAo+MlX1MYq6dbEfzSEttAZKty9oyMFYxGmKBV2fMGRZNwqtddDGsJ0k6/0qawq1KgBdA0a9hdVwVQ5T7GHLDG3+BJ3toLNO0jpB9+8hv5Q4wu/Ln/hx8QW/vl0wSL/8/hXFLgWsgdMKh1Y/QwHOIksTrIMpHG3UGk4DE6pdjNXdGIvJ6BV/h1jejtE8JRydRCOgNbKV2whtkTHh6z2kilrzFs6ybJuaj/QeqirZZlQD3a+yNAAld8sY1hte16wreUlcLANjbQdjO3/hUApe1ywtv+eiKLh7SEsjG4E2kqZKXWa323FDkQ4lfgRP3io5P24euRlordAf5CiDiKhZhFGIKOoabn/8A4BSssMaxQLOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-user-password",
            "title": "vscode-user-password",
            "src": "/static/3267983844ab8112af9d3b41bcd0fadd/045fd/vscode-user-password.png",
            "srcSet": ["/static/3267983844ab8112af9d3b41bcd0fadd/12f09/vscode-user-password.png 148w", "/static/3267983844ab8112af9d3b41bcd0fadd/e4a3f/vscode-user-password.png 295w", "/static/3267983844ab8112af9d3b41bcd0fadd/045fd/vscode-user-password.png 403w"],
            "sizes": "(max-width: 403px) 100vw, 403px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "step-2-starting-visual-studio-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#step-2-starting-visual-studio-code",
        "aria-label": "step 2 starting visual studio code permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2: Starting Visual Studio Code`}</h2>
    <p>{`There are two main ways to start Visual Studio Code.`}</p>
    <h3 {...{
      "id": "option-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#option-1",
        "aria-label": "option 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Option 1`}</h3>
    <p>{`In the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Activities`}</code>{` search bar type `}<em parentName="p">{`Visual Studio Code`}</em>{` and click on the icon to launch the application.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4cc66932b585e4fad3f857c8da46ff6e/fbf76/vscode-search.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.05405405405405%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACYklEQVQozz2SSW/TUBSF73OGNlBRGo9xPDue4jgJqEA3CLFigZSCmMtUqBAtVIBYILFjgfjbH7KpWBzpXd1zvnsWT44fP+LZZsOLw0OOOj3g5YW+nJzw/fQj38/O+PntKz/OP3P6+hVHm8Nu33rb3PPNhqOHD3nz9CliimCLMBHBEyEQRSiKRDTO79/n1/sT/rx7w+/jt/w5O+PJtX18EWLpEYgwvchaIhgiSDLcI9vSKbcN5iOTxcikGVlc23FYj0z2dxzmd49Z33nJWu2wumRzc3fC9SsO9cik2jYptg1mW2Pi4R4S9nQSpZOITq4sqp5NpTk0fZdl3+XWTsTBvU8c3P3AWgyWWz43LnustlxKzSbTLFJlEGs6oTZGAjFJRx61lVGNE8qrEQsjY2nmNEbG2i65HdTcms5pjJxGn7EyU+oLb74bku0GRIMJvjIRVyxKK+Ng/4AqKSmiglW5ZF2tWBVLFrMF9WzBMl+yzBrqdE4ZFpRRQRHl5GFOEedEIw9XbGQiDr42Jex5JP2Q2SAh66dUg4xqUFAPKpphzWIwZz4oqQY51TCjGKbM+jFxPyTs+3jKxWmBtti44hKIRyQBiURkKqXs5ZSqoFIVjVbT9GrmWkWpcjKZkUpCrCJC8fFkykQm2GK138biH3SKLz6hCkm1mExLybWMXOWUWkGuiu6dqRmJSohURKACfOUzERdLbFqWGGJidFCnW0xliic+gYTEEnXhVCXEEhNJTCgRvgRMxeu8bbMW1jJaloxljC5GN7QX2mULd8TtWrdqw554/2dHJp3si1a6mIxFZ0/G/AUyii3WnOnn+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-search",
            "title": "vscode-search",
            "src": "/static/4cc66932b585e4fad3f857c8da46ff6e/fcda8/vscode-search.png",
            "srcSet": ["/static/4cc66932b585e4fad3f857c8da46ff6e/12f09/vscode-search.png 148w", "/static/4cc66932b585e4fad3f857c8da46ff6e/e4a3f/vscode-search.png 295w", "/static/4cc66932b585e4fad3f857c8da46ff6e/fcda8/vscode-search.png 590w", "/static/4cc66932b585e4fad3f857c8da46ff6e/efc66/vscode-search.png 885w", "/static/4cc66932b585e4fad3f857c8da46ff6e/c83ae/vscode-search.png 1180w", "/static/4cc66932b585e4fad3f857c8da46ff6e/fbf76/vscode-search.png 1252w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "option-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#option-2",
        "aria-label": "option 2 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Option 2`}</h3>
    <p>{`Open your terminal and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`code`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e13d59824aa3351147fc2358475eaa27/78797/vscode-start-with-terminal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.1891891891892%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVklEQVQ4y3WSS2/TQBRG/S+a2J63x3k5Tmwncdy8WtRUsOoKJKhUECAEAhZtWiEkkko0JfCrPzQTN0qRWBzdu5lzX+N8HXeQJwnytKTMi+4Aw7iPfjNFHvUwSQpM0gLjZIhpdogi7mPQypAboszmg6gH5838FUZRE0fDLmb9DmZZglm/i3kywXGjwKyWYx6NcdIa4TSe4kmjwFhlGAe9bSyZ6D5OZQrn7fMPuDh7idXdGteLGywW11gsbnC1uMLl1SV+rJbY/N7g/tc9Nn82+Lm+w/J2idXtasdytcR6s8bNpy9wvl28RU/FqMcx4riDKIrQbsdot9s2tpotcMYhhIDgAkJISKkeIySUDqBJAOf86ASZ6kBphTRNkec5iuEQSZJYeaPRgOd5IITA9/3/4hEPokLhPHv6znYY1mtglFgeHruua2WUUgvnHIwxEOKDElKyzQkjkFUG5/t8jkwnqNVDiGYOHnZAGd9JDKaAIQgChGEInwm4hMMjHC4R8AlD1fe3wrPpC2Qqhq5p+FSCihCUiW3Vf6RSStTCEDIIUfXZDtenqHgeRJXC6Y5eIxMRVKihAwUl92XkkdCMbKRSCDuqXQsRqBIFl/hb4bQ+QCpjBGGw2xMtR7SyMj50aK5t9mqPQYjNKwcHqLgVCDNydvwePd2BCgNwoUC53ML4TvoQTTGyV8wcyETfM1cud/jx/DO6PLId8lYB2TkG0227x12XpdQcRWuNINDltUsxZfDtyAzOoR4gkW0rZI0cIhqBcrX9Gnvd7csNZlTzrezXcj1UPdcK/wIi5YqLLfyfpwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vscode-start-with-terminal.png",
            "title": "vscode-start-with-terminal.png",
            "src": "/static/e13d59824aa3351147fc2358475eaa27/fcda8/vscode-start-with-terminal.png",
            "srcSet": ["/static/e13d59824aa3351147fc2358475eaa27/12f09/vscode-start-with-terminal.png 148w", "/static/e13d59824aa3351147fc2358475eaa27/e4a3f/vscode-start-with-terminal.png 295w", "/static/e13d59824aa3351147fc2358475eaa27/fcda8/vscode-start-with-terminal.png 590w", "/static/e13d59824aa3351147fc2358475eaa27/efc66/vscode-start-with-terminal.png 885w", "/static/e13d59824aa3351147fc2358475eaa27/78797/vscode-start-with-terminal.png 1125w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This should cause VS Code to launch.`}</p>
    <h1 {...{
      "id": "learn-more",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#learn-more",
        "aria-label": "learn more permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learn more`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/docs/getstarted/introvideos",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Introductory videos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/docs/editor/codebasics",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Documentation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/docs/remote/remote-overview",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Remote editing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/docs/editor/debugging",
          "target": "_self",
          "rel": "nofollow"
        }}>{`Debugger`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      